import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell blanquinós rosat a beix amarronat de fins a 4 cm de diàmetre que passa de convex a pla i umbilicat al centre. Presenta fibril·les radials fins al marge del capell, que és estriat. En temps humit pot ser viscós. Les làmines són blanques i amb moltes lamel·les. Les espores són blanquinoses en massa, llises, cilíndrico-el·lipsoidals, de 6,6-7,5 x 4 micres. El peu s’engruixa a la part superior i és de color blanquinós a la part de dalt i marró rogenc cap a la part de baix.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      